
<template>
    <h2 class="font-semibold text-xl text-gray-800 leading-tight">
        {{$props.title}}
    </h2>
</template>

<script>

export default {
    props: ['title']
}
</script>
<style lang="">
    
</style>