<template lang="">
    <div class="bg-white overflow-hidden  shadow-sm sm:rounded-lg">
        <div :class="$props.class || 'p-6' + `overflow-x-scroll bg-white border-b border-gray-200`">
            <slot/>
        </div>
    </div>
</template>
<script>
export default {
    props: ['class']
}
</script>
<style lang="">
    
</style>