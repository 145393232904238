<template>
  <div ref="animationContainer" class="lottie-animation"        
      @mouseenter="playAnimation(false)" 
       @mouseleave="playAnimation(true)"></div>
</template>

<script>
import lottie from 'lottie-web';
import {markRaw} from 'vue';
export default {
  name: 'LottieAnimation',
  data(){
    return({
      animation: null
    })
  },
  props: {
    animationData: {
      type: Object,
      required: true
    }
  },
  mounted() {
    this.loadAnimation();
  },
  methods: {
    playAnimation(reverse) {
      this.animation.setDirection(reverse ? -1 : 1);
      this.animation.play();
    },
    stopAnimation() {
      this.animation?.stop();
    },
    
    loadAnimation() {
      this.animation = lottie.loadAnimation({
        container: this.$refs.animationContainer, 
        renderer: 'svg',
        loop: false,
        autoplay: false,
        animationData: markRaw(this.animationData) // JSON-data fra prop
      })
    }
  }
};
</script>

<style scoped>

</style>
