<template lang="">
    <Transition>
        <div v-show="show" :class="wrapperClass" role="alert">
            <div class="flex">
                <div class="py-1">
                    <BadgeCheckIcon class="text-green-500 h-6 w-6 mr-2" v-if="type == 'success'"></BadgeCheckIcon>
                    <XCircleIcon class="text-red-500 h-6 w-6 mr-2" v-if="type == 'error'"></XCircleIcon>
                    <ExclamationCircleIcon class="text-blue-500 h-6 w-6 mr-2" v-if="type == 'info'"></ExclamationCircleIcon>
                </div>
                <div>
                    <p :class="{'font-semi-bold': true, 'mt-1': (message ? false : true)}">{{title}}</p>
                    <p class="text-sm" v-if="message">{{message}}</p>
                </div>
            </div>
        </div>
    </Transition>
</template>

<script>
import { BadgeCheckIcon, XCircleIcon, ExclamationCircleIcon } from '@heroicons/vue/outline'

export default {
    props: ['message', 'title', 'type'], 
    data: function(){
        return({
            show: true
        })
    }, 
    mounted: function(){
        setTimeout(() => {
            this.show = false; 
        }, 5000);
    }, 
    computed: {
        wrapperClass: function(){
            const type = this.type; 
            const baseCss = 'bg-white z-50 rounded-lg border-l-4 rounded-b text-teal-900 px-4 py-3 shadow-md fixed  bottom-10 right-10';

            if (type == 'success'){
                return baseCss + ' border-green-500'
            }

            if(type == 'error'){
                return baseCss + ' border-red-500'
            }

            return baseCss + ' border-blue-500'
        }
    },
     components: {BadgeCheckIcon, XCircleIcon, ExclamationCircleIcon}
}
</script>
<style lang="css">
    .v-enter-active,
    .v-leave-active {
        transition: opacity 0.5s ease;
    }

    .v-enter-from,
    .v-leave-to {
        opacity: 0;
    }
</style>